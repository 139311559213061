import React, { Component } from 'react'
import Helmet from 'react-helmet'
import { graphql, Link } from 'gatsby'
import Layout from '../layout'
import PostListing from '../components/PostListing'
import ProjectListing from '../components/ProjectListing'
import InitiativeListing from '../components/InitiativeListing'
import SimpleListing from '../components/SimpleListing'
import SEO from '../components/SEO'
import config from '../../data/SiteConfig'
import projects from '../../data/projects'
import sideprojects from '../../data/initiatives'
import opensource from '../../data/opensource'
import interviews from '../../data/interviews'
import speaking from '../../data/speaking'
import supratim from '../../content/images/personal_picture.png'

export default class Index extends Component {
  render() {
    const { data } = this.props

    const latestPostEdges = data.latest.edges
    const popularPostEdges = data.popular.edges

    return (
      <Layout>
        <Helmet title={`${config.siteTitle}`} />
        <SEO />
        <div className="container">
          <div className="lead">
            <img src={supratim} className="personal-picture" alt="Supratim" />
            <div className="elevator">
              <h1>{`Hey, I am Supratim`}</h1>
              <p>
                I am a product designer from Vancouver. I love building software and pursue an interest in HCI research and community development. 
              </p>
            </div>
          </div>
        </div>

        <div className="container front-page">
          <section className="section">
            <h2>Works</h2>
            <ProjectListing projects={projects} />
          </section>

          <section className="section">
            <h2>Other fun things I do</h2>
            <InitiativeListing projects={sideprojects} />
          </section>

          {/* <section className="section">
            <h2>{`Other fun things I do`}</h2>
            <div className="initiatives">
              {initiatives.map(initiative => (
                <blockquote className="initiative" key={initiative.name}>
                  <a href={initiative.link} target="_blank" rel="noopener noreferrer"><img className="initiative-img" src={initiative.img}></img></a>
                  <a href={initiative.link} target="_blank" rel="noopener noreferrer"><div className="initiative-name">{initiative.name}</div></a>
                  <a href={initiative.link} target="_blank" rel="noopener noreferrer"><p className="initiative-description">{initiative.description}</p></a>
                </blockquote>
              ))}
            </div>
          </section> */}

          <section className="section">
            <h2>
              Latest Articles
              <Link to="/blog" className="view-all">
                View all
              </Link>
            </h2>
            <PostListing simple postEdges={latestPostEdges} />
          </section>

          <section className="section">
            <h2>Interviews</h2>
            <SimpleListing simple data={interviews} />
          </section>

          <section className="section">
            <h2>Open Source</h2>
            <SimpleListing simple data={opensource} />
          </section>

          <section className="section">
            <h2>Talks</h2>
            <SimpleListing simple data={speaking} />
          </section>
        </div>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query IndexQuery {
    latest: allMarkdownRemark(
      limit: 6
      sort: { fields: [fields___date], order: DESC }
      filter: { frontmatter: { template: { eq: "post" } } }
    ) {
      edges {
        node {
          fields {
            slug
            date
          }
          excerpt
          timeToRead
          frontmatter {
            title
            categories
            date
            template
          }
        }
      }
    }
    popular: allMarkdownRemark(
      limit: 7
      sort: { fields: [fields___date], order: DESC }
      filter: { frontmatter: { categories: { eq: "Popular" } } }
    ) {
      edges {
        node {
          fields {
            slug
            date
          }
          excerpt
          timeToRead
          frontmatter {
            title
            categories
            date
            template
          }
        }
      }
    }
  }
`

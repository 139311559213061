import xodoimg from '../content/images/xodo_hero_homepage.png'
import pdfonlineimg from '../content/images/pdfonline_hero.png'
import vizinteraktimg from '../content/images/vizinteract_hero.png'
import one_mg_img from '../content/images/1mg_hero.png'
import playsolarimg from '../content/images/playsolar_hero.png'
import qkcontactsimg from '../content/images/qkcontacts_hero.png'


export default [
  {
    img: xodoimg,
    title: 'Xodo Pro',
    path: 'projects/xodowork',
    description: 'Designing paid components for a popular free app',
    problem:'',
    solution: '',
    role: 'End to end design as a Lead Designer for Xodo',
  },
  {
    img: pdfonlineimg,
    title: 'PDFOnline',
    path: 'projects/pdfonline',
    description: 'Designing a Next generation PDF Viewer for web',
    problem:'',
    solution: '',
    role: 'End to end design as a Lead Designer for Xodo',
  },
  {
    img: vizinteraktimg,
    title: 'VizInteract',
    path: 'projects/vizinteract',
    description: 'A gesture driven data visualization tool',
    problem:'Creating and analyzing data visualizations with current tools typically involve complex interactions involving a mouse and keyboard',
    solution: 'An interactive data visualiztion tool that uses touch gestures for creating, editing, and analyzing visualizations',
    role: 'End to end design and development of software. Pilot studies defined design goals and traditional qualititave experiments were conducted for validating design choices',
  },
  {
    img: one_mg_img,
    title: '1mg',
    path: 'projects/1mg',
    description: 'Redesigning navigation heirarchy and medicine product pages',
    problem:'The original navigation component for 1mg obscured service heirarchy while the product page with its dense editorial layout made information parsing tedious',
    solution: 'A multi-tab header layout that houses all 1mg services and provides consistent navigation ux and an SEO-forward interactive components driven product page',
    role: 'Discovery, user research, design and testing',
  },
  {
    img: playsolarimg,
    title: 'Digital QA',
    path: 'projects/playsolar',
    description: 'Application for GE to manage solar installation projects end to end',
  },
  {
    img: qkcontactsimg,
    title: 'QKContacts',
    path: 'projects/qkcontacts',
    description: 'A smart interface for quick capturing and editing of contact details.',
    problem:'Capturing or editing information about people you meet or want to stay connected with, is a tedious multi-step input process.',
    solution: 'Using a smart natural language interface, QKContacts allows quick capture and editing of contacts.',
    role: 'Design, development and general market delivery of the app. Beta test for the Android version is coming soon',
  }
]

import andev from '../content/thumbnails/360andev.png'
import oss from '../content/thumbnails/oss.png'
import android from '../content/thumbnails/android.png'
import dt from '../content/thumbnails/dt.png'
import sic from '../content/thumbnails/sic.png'
import devhub from '../content/thumbnails/devhub.png'
import gdg from '../content/thumbnails/gdg.png'
import ieee from '../content/thumbnails/ieee.png'

export default [
  {
    title: 'Building One of the World\'s Largest Interactive Data Visualisation Experiences - DroidConSF 2019',
    path: 'https://www.droidcon.com/media-detail?video=380851514',
    img: android,
  },
  {
    title: 'Using Open Source to Build an interactive data visualization experience - Open Soure Summit 2019',
    path: 'https://web.archive.org/web/20200110202257/https%3A%2F%2Fossna19.sched.com%2Fevent%2FPUSy%2Fusing-open-source-to-build-one-of-the-worlds-largest-interactive-data-visualization-experiences-supratim-chakraborty-simon-fraser-university-vancouver',
    img: oss,
  },
  {
    title: 'Android Canvas: Building interactive data visualization experiences - 360Andev 2019',
    path:
      'https://web.archive.org/web/20200110205211/https://360andev.com/sessions/android-canvas-building-one-of-the-worlds-largest-interactive-data-visualization-experiences/',
    img: andev,
  },
  {
    title: 'How can digital designers participate in the Sustainability dialogue - DesignThinkers 2019',
    path:
      'https://www.youtube.com/watch?v=Hyc6HZdu4H8&feature=youtu.be',
    img: dt,
  },
  {
    title: 'Sustainability and Digital Design At Scale - Seattle Interactive Conference 2018',
    path:
      'https://www.slideshare.net/seattleinteractive/sustainability-and-digital-design-at-scale-seattle-interactive-conference-2018',
    img: sic,
  },
  {
    title: 'Critical Thinking in Product Design - IEEE Young Professional Development Workshop 2018',
    path:
      'https://vancouver.ieee.ca/event/critical-thinking-in-digital-product-design/',
    img: ieee,
  },
  {
    title: 'Building Design Systems Using Sketch - Devhub Vancouver 2019',
    path:
      'https://www.meetup.com/DevhubVancouver/events/261942843/',
    img: devhub,
  },
  {
    title: 'Innovations and trends in Design Research - Google Developer Group New Delhi IO Extended 2018',
    path:
      'https://www.meetup.com/GDGNewDelhi/events/250303488/',
    img: gdg,
  },
]

import React, { Component } from 'react'

export default class ProjectListing extends Component {
  render() {
    const { projects } = this.props

    return (
      <section className="projects">
        {projects.map(project => (
          <div className="each" key={project.title}>
              
              <a
                className="project-link"
                href={project.path}
                target="_blank"
                rel="noopener noreferrer"
              >
                {project.img && (<img className="project-icon" src={project.img}></img>)}
                <div className="project-title">{project.title}</div>
              </a>
            <p className='project-description'>{project.description}</p>
            {/* <p className='project-headings'>Problem Space</p>
            <p className='project-text'>{project.problem}</p>
            <p className='project-headings'>Solution</p>
            <p className='project-text'>{project.solution}</p>
            <p className='project-headings'>Role</p>
            <p className='project-text'>{project.role}</p> */}
            {/* <div>
              {project.path && (
                <a className="case-study-link" href={project.path} target="_blank" rel="noopener noreferrer">
                  Read more..
                </a>
              )}
            </div> */}
          </div>
        ))}
      </section>
    )
  }
}

import idiotowls from '../content/images/idiotowls.png'
import uxsymphony from '../content/images/uxsymphony.png'
import dd from '../content/images/dd.png'
import ddpodcast from '../content/images/ddpodcast.png'
import ddyoutube from '../content/images/ddyoutube.png'
import sketchvancouver from '../content/images/sketchvancouver.png'
import gdgsurrey from '../content/images/gdgsurrey.png'
import sfuiat100 from '../content/images/sfuiat100.png'

export default [
  {
    name: 'Idiot Owls',
    description: `Digital product studio working with organizations for societal good.`,
    link: 'https://idiotowls.com',
  img: idiotowls,
  },
  {
    name: 'UX Symphony',
    description: 'Portfolio mentoring programme for students',
    link: 'https://uxsymphony.com',
    img: uxsymphony,
  },
  {
    name: 'Open Data for Design',
    description: `Repository of resources for humane design.`,
    link: 'https://data.designdiscussion.co',
    img: dd,
  },
  {
    name: 'Design Discussion Podcast',
    description: `Sharing research papers from design that impacts our work.`,
    link: 'https://podcast.designdiscussion.co',
    img: ddpodcast,
  },
  {
    name: 'Design Discussion YouTube',
    description: `Personal opinions on design and technology.`,
    link: 'https://www.youtube.com/channel/UCmfqB_e_6kjSM9Q648XDEyg',
    img: ddyoutube,
  },
  {
    name: 'Google Developer Group Surrey',
    description: `Community of developers interested in Open Source.`,
    link: 'https://www.meetup.com/GDG-Surrey/',
    img: gdgsurrey,
  },
  {
    name: 'Sketch Vancouver',
    description: `Community of designers sharing experiences, wins and failures.`,
    link: 'https://www.meetup.com/Sketch-Vancouver/',
    img: sketchvancouver,
  },
]

export default [
  {
    title: 'Product Designer Stories - Interview with Dave Martin of userleague.com',
    path: 'https://userleague.com/supratim-chakraborty/',
  },
  {
    title: 'An 85 inch "tablet" for data visualization - Simon Fraser University Research Feature',
    path: 'https://www.sfu.ca/siat/stories/research/an-85-inch-tablet-for-data-visualization.html',
  },
]

export default [
    {
      title: 'MaterialDateRangePicker - A popular android library for improving UX of picking range of dates',
      path: 'https://github.com/heysupratim/MaterialDateRangePicker',
    },
    {
      title: 'ZenTrack - A minimal mac app for tracking app usage developed using Swift',
      path: 'https://github.com/heysupratim/ZenTrack',
    },
    {
      title: 'FairerEmail - An experimental redesign of FairEmail, an open source email client for Android',
      path:
        'https://github.com/heysupratim/FairerEmail',
    },
    {
      title: 'WFramer - a barebones wireframing tools with basic elements support, built using Processing',
      path:
        'https://github.com/heysupratim/WFramer',
    },
    {
      title: 'Retain-It - Electron app for note taking that uses spaced repetition to retain knowledge',
      path:
        'https://github.com/heysupratim/Retain-It',
    },
  ]